import { Box, Button, Card, Grid, styled, TextField } from '@mui/material';
import { useState } from 'react';
import Parse from '../vendor/Parse'
import { Formik } from 'formik';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: 32,
  background: theme.palette.background.default,
}));

const ForgotPasswordRoot = styled(JustifyBox)(() => ({
  '& .card': {
    maxWidth: 800,
    margin: '1rem',
    borderRadius: 12,
  },
}));

const initialValues = {
  email: ""
}

const ForgotPassword = () => {

  const [email, setEmail] = useState('');
  const [showEndPage, setShowEndPage] = useState(false)
  const handleFormSubmit = async (values) => {
    const response = await Parse.Cloud.run('forgetPassword', {
      email: values.email
    })
    setShowEndPage(true)
  };

  return (
    <ForgotPasswordRoot>
      <Card className="card">
        <Grid container>
          <Grid item xs={12}>
            <ContentBox>
              {
                !showEndPage &&
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleFormSubmit}
                >
                  {
                    ({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          type="email"
                          name="email"
                          size="small"
                          label="Email"
                          value={values.email}
                          variant="outlined"
                          onChange={handleChange}
                          sx={{ mb: 3, width: '100%' }}
                        />

                        <Button fullWidth variant="contained" color="primary" type="submit">
                          Reset Password
                        </Button>
                      </form>
                    )

                  }
                </Formik>
              }

              {
                showEndPage && <Box>
                  Please Check your email to reset your password.
                </Box>
              }
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </ForgotPasswordRoot>
  );
};

export default ForgotPassword;
